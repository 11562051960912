import * as React from 'react';

const NavigationPanel = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M0 8H8V0H0V8ZM12 32H20V24H12V32ZM0 32H8V24H0V32ZM0 20H8V12H0V20ZM12 20H20V12H12V20ZM24 0V8H32V0H24ZM12 8H20V0H12V8ZM24 20H32V12H24V20ZM24 32H32V24H24V32Z"
        fill="white"
      />
    </svg>
  );
};

export default NavigationPanel;
