/* eslint-disable no-param-reassign */

import { isString } from 'formik';
import { requestUploadFile } from 'utils/requestUploadFile';
import i18next, { t } from 'i18next';
import { Localization } from './config';

// eslint-disable-line
// TODO: check if the Localization block in this file is sufficient
// ^ (or should be dynamic)
export { Localization } from './config';

export const TVS_FORMAT_DATE = 'DD/MM/yyyy';
export const BE_FORMAT_DATE = 'yyyy-MM-DD';

export const STAGE = ['Repossessed', 'Seized', 'Inward', 'Released'];
export const REPO_STAGE = {
  REPOSSESSED: 'Repossessed',
  SEIZED: 'Seized',
  INWARD: 'Inward',
  RELEASED: 'Released',
};

export const ACCURACY_MAPPING = {
  LOW: {
    color: '#CF0000',
    text: 'Low',
  },
  MED: {
    color: '#F35F04',
    text: 'Medium',
  },
  HIGH: {
    color: '#00A061',
    text: 'High',
  },
};

export const checkForCompactness = (value: string | number) => {
  const shortHandValuesDefault = ['k', 'M', 'B'];
  const shortHandValuesIndian = ['T', 'L', 'Cr'];

  if (Localization.currencyLocale === 'en-IN') {
    for (const shortHandValue of shortHandValuesIndian) {
      if (value.toString().includes(shortHandValue)) {
        return true;
      }
    }
  } else {
    for (const shortHandValue of shortHandValuesDefault) {
      if (value.toString().includes(shortHandValue)) {
        return true;
      }
    }
  }

  return false;
};

export const formatCurrency = (value: string | number, noDecima?: boolean) => {
  if (value === null || value === undefined || value === '') return '';

  if (checkForCompactness(value)) {
    return value;
  }

  const num = Number(value?.toString().replace(/,/gi, ''));

  const currencyValue = new Intl.NumberFormat(Localization.currencyLocale, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(num);

  return currencyValue;
  // const splitDecima = num.toString().split('.');
  // let num2;
  // if (+splitDecima[0] >= 0) {
  //   num2 = splitDecima[0].split(/(?=(?:\d{3})+$)/).join(',');
  // } else {
  //   num2 = `- ${Math.abs(+splitDecima[0])
  //     .toString()
  //     .split(/(?=(?:\d{3})+$)/)
  //     .join(',')}`;
  // }
  // if (noDecima) {
  //   return `${num2}${splitDecima[1] ? `.${splitDecima[1]}` : ''}`;
  // }
  // return `${num2}${splitDecima[1] ? `.${splitDecima[1]}` : '.00'}`;
};

export const PAYMENT_NOTES = ['Full Payment', 'Partial Payment'];

export const PTP_LOCATION = ['Branch', 'Field'];

export const OWNER_OF_PHONE_NUMBER = [
  'Customer',
  'Parent / Parent-in-law',
  'Sibling',
  'Child / Grandchild',
  'Grandparent',
  'Spouse / Partner',
  'Friend',
  'Other',
];

export const TCM_MANAGER = 'TCM';
export const ACM_MANAGER = 'ACM';
export const RCM_MANAGER = 'RCM';
export const OPS_MANAGER = 'OPS';

export const CASHIER_ROLE = 'A';
export const MANAGER_ROLE = 'M';
export const CORPORATE_ROLE = 'C';
export const BORROWER_ROLE = 'B';
export const DEALER_ROLE = 'D';
export const COLLECTOR_ROLE = 'F';
export const SUPERUSER_ROLE = 'S';
export const AUDIT_ROLE = 'U';

export const PARTIAL_PAYMENT = 'Partial Payment';
export const PROMISE_TO_PAY = 'Promise to Pay';
export const CALL_BACK_LATER = 'Call Back Later';
export const FULL_PAYMENT = 'Full Payment';
export const ALREADY_MADE_PAYMENT = 'Already Made Payment';
export const NOT_CONTACTABLE = 'Not Contactable';
export const LEFT_MESSAGE = 'Left Message';
export const NOT_VISITED = 'Not Visited';
export const FORECLOSURE = 'Foreclosed';
export const FORECLOSURE_PAYMENT = 'Foreclosure Payment';
export const REQUEST_FOR_OTS = 'Request for OTS';
export const CUSTOMER_EXPIRED = 'Customer Expired';
export const CUSTOMER_EXPIRY = 'Customer Expiry';
export const REFERRAL_TO_RCU = 'Referral to RCU';
export const ONLINE_PAYMENT_REQUEST = 'Online Payment Request';
export const WALLET_PAYMENT_REQUEST = 'Wallet Payment Request';
export const SETTLEMENT = 'Settlement';
export const UNABLE_TO_PAY = 'Unable to Pay';
export const REFERRAL_TO_TMBD = 'Referral to TMBD';

export const isStyleObject = (obj: any) => typeof obj === 'object';
// export const TERM_VERSION = 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/eula/EULA_webapp.json';
export const IDLE_TIMEOUT = 1800;

export const ADDRESS_TYPE = [
  {
    id: 'Home',
    value: 'Home',
    label: 'Home',
  },
  {
    id: 'Office',
    value: 'Office',
    label: 'Office',
  },
  {
    id: 'Relative',
    value: 'Relative',
    label: 'Relative',
  },
  {
    id: 'Comaker',
    value: 'Comaker',
    label: 'Comaker',
  },
  {
    id: 'Other',
    value: 'Other',
    label: 'Other',
  },
];

export const NEPAL_MONTH_LIST = [
  'Baishakh',
  'Jestha',
  'Ashadh',
  'Shrawan',
  'Bhadau',
  'Ashwin',
  'Kartik',
  'Mangsir',
  'Poush',
  'Magh',
  'Falgun',
  'Chaitra',
];

export const MONTH_LIST = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const NEPAL_TZ = 'Asia/Kathmandu';

// **** End of Localization for Nepal ****

// Credit Debit Memo Feature changes
export const MEMO_TYPE = Localization.hiddenPenaltyField
  ? ['Principal Credit', 'Principal Debit']
  : ['Principal Credit', 'Principal Debit', 'Penalty Credit', 'Penalty Debit'];

const MEMO_REASON_CODE_PPD = Localization.activatePPD ? ['Missing PPD adjustment'] : [];
const MEMO_REASON_CODE_PENALTY = Localization.hiddenPenaltyField ? [] : ['Penalty discount approved by management'];

export const MEMO_REASON_CODE = [
  'General adjustment',
  'Corrections on misposted transactions',
  ...MEMO_REASON_CODE_PPD,
  'Principal discount approved by management',
  ...MEMO_REASON_CODE_PENALTY,
  'Others',
];

const TRANSACTION_TYPE_PPD = Localization.activatePPD ? [{ id: 'D', value: 'PPD', label: 'PPD' }] : [];

const TRANSACTION_TYPE_PENALTY_FIELDS = [
  { id: 'PECM', value: 'Penalty Credit', label: 'Penalty Credit' },
  { id: 'PEDM', value: 'Penalty Debit', label: 'Penalty Debit' },
];

const TRANSACTION_TYPE_PENALTY = Localization.hiddenPenaltyField ? [] : TRANSACTION_TYPE_PENALTY_FIELDS;

export const TRANSACTION_TYPE = [
  {
    id: 'All',
    value: 'All Transactions',
    label: 'All Transactions',
  },
  {
    id: 'P',
    value: 'Payment',
    label: 'Payment',
  },
  ...TRANSACTION_TYPE_PPD,
  {
    id: 'PRCM',
    value: 'Principal Credit',
    label: 'Principal Credit',
  },
  {
    id: 'PRDM',
    value: 'Principal Debit',
    label: 'Principal Debit',
  },
  ...TRANSACTION_TYPE_PENALTY,
  {
    id: 'M',
    value: 'All credit/debit memo',
    label: t('All credit/debit memo'),
  },
];

/**
 * Parent function that formats value compactly based on locale value
 * @param {*} num the number to displayed compactly
 * @param {*} locale locale
 * @returns compacted value with upto 2 decimal digits
 */
export const kFormatter = (num: number | string, smallNumNoDecimal?: number) => {
  if (smallNumNoDecimal === undefined || smallNumNoDecimal === null) {
    smallNumNoDecimal = 1;
  }
  if (checkForCompactness(num) || isString(num)) {
    return num;
  }

  if (Localization.currencyLocale === 'en-IN') {
    return kFormatterIndian(num, smallNumNoDecimal); // Returns the value as per the Indian numbering system of T, L, Cr etc
  }

  return kFormatterDefault(num); // Returns the value as per the international system of k, M, B etc
};

export const kFormatterDefault = (num: number, smallNumNoDecimal?: number) => {
  const absValue = Math.abs(num);
  const signNum = Math.sign(num);

  if (absValue >= 1e5 && absValue < 1e6) {
    return `${numberWithCommas((signNum * (absValue / 1e3)).toFixed(smallNumNoDecimal))}k`;
  }
  if (absValue >= 1e6 && absValue < 1e9) {
    return `${numberWithCommas((signNum * (absValue / 1e6)).toFixed(smallNumNoDecimal))}M`;
  }
  if (absValue >= 1e9 && absValue < 1e12) {
    return `${numberWithCommas((signNum * (absValue / 1e9)).toFixed(smallNumNoDecimal))}B`;
  }

  return numberWithCommas(absValue.toFixed(smallNumNoDecimal ? 0 : smallNumNoDecimal));
};

/**
 * Returns the value compactly as per Indian numbering system of T, L, Cr etc
 * @param {*} num
 * @returns the compacted value with upto 2 decimal digits
 */
export const kFormatterIndian = (num: number, smallNumNoDecimal?: number) => {
  const absValue = Math.abs(num);
  const signNum = Math.sign(num);

  // if (n >= 1e3 && n < 1e5) {
  //   return this.numberWithCommas(sign_num * (n / 1e3).toFixed(2)) + 'T';
  // } else
  if (absValue >= 1e5 && absValue < 1e7) {
    return `${numberWithCommas((signNum * (absValue / 1e5)).toFixed(smallNumNoDecimal))}L`;
  }
  if (absValue >= 1e7 && absValue < 1e18) {
    return `${numberWithCommas((signNum * (absValue / 1e7)).toFixed(smallNumNoDecimal))}Cr`;
  }

  // Return the actual value without any compacting in appropriate locale format
  const value = new Intl.NumberFormat(Localization.currencyLocale, {
    maximumFractionDigits: smallNumNoDecimal,
    minimumFractionDigits: smallNumNoDecimal,
  }).format(num);

  return value;
};

/**
 * Parent function that formats value compactly based on locale value
 * @param {*} num the number to displayed compactly
 * @param {*} locale locale
 * @returns compacted value with upto 0 decimal digits
 */
export const commaFormatter = (num: number | string) => {
  if (checkForCompactness(num) || isString(num)) {
    return num;
  }

  if (Localization.currencyLocale === 'en-IN') {
    return commaFormatterIndian(num); // Returns the value as per the Indian numbering system of T, L, Cr etc
  }

  return commaFormatterDefault(num); // Returns the value as per the international system of k, M, B etc
};

export const commaFormatterDefault = (num: number) => {
  const absValue = Math.abs(num);
  const signNum = Math.sign(num);

  if (absValue >= 1e5 && absValue < 1e6) {
    return `${numberWithCommas((signNum * (absValue / 1e3)).toLocaleString())}k`;
  }
  if (absValue >= 1e6 && absValue < 1e9) {
    return `${numberWithCommas((signNum * (absValue / 1e6)).toLocaleString())}M`;
  }
  if (absValue >= 1e9 && absValue < 1e12) {
    return `${numberWithCommas((signNum * (absValue / 1e9)).toLocaleString())}B`;
  }

  return numberWithCommas(absValue);
};

/**
 * Returns the value compactly as per Indian numbering system of T, L, Cr etc
 * @param {*} num
 * @returns the compacted value with upto 2 decimal digits
 */
export const commaFormatterIndian = (num: number) => {
  const absValue = Math.abs(num);
  const signNum = Math.sign(num);

  // if (n >= 1e3 && n < 1e5) {
  //   return this.numberWithCommas(sign_num * (n / 1e3).toFixed(2)) + 'T';
  // } else
  if (absValue >= 1e5 && absValue < 1e7) {
    return `${numberWithCommas((signNum * (absValue / 1e5)).toLocaleString())}L`;
  }
  if (absValue >= 1e7 && absValue < 1e18) {
    return `${numberWithCommas((signNum * (absValue / 1e7)).toLocaleString())}Cr`;
  }

  // Return the actual value without any compacting in appropriate locale format
  const value = new Intl.NumberFormat(Localization.currencyLocale, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(num);

  return value;
};

export const numberWithCommas = (x: number | string) => {
  const y = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return y.replace(/\.00$/, '');
};
export const BUCKET_LEVEL = [
  {
    id: 'B0',
    value: 'B0',
    label: 'B0',
    color: '#39B542',
  },
  {
    id: 'B2',
    value: 'B2',
    label: 'B2',
    color: '#ED9807',
  },
  {
    id: 'B4-B6',
    value: 'B4-B6',
    label: 'B4-B6',
    color: '#E22B16',
  },
];

export const RESEND_COUNTDOWN = 300; // resend otp countdown for user verification in seconds

export const VISIT_STATUS_OPTIONS = [
  {
    id: 'visited',
    value: 'visited',
    label: 'Visited',
  },
  {
    id: 'not-visited',
    value: 'not-visited',
    label: 'Not Visited',
  },
];

export const VISITS_OPTIONS = [
  {
    id: 'ptp',
    value: 'ptp',
    label: 'PTP',
  },
  {
    id: 'mandatory',
    value: 'mandatory',
    label: 'Priority 1',
  },
  {
    id: 'regular',
    value: 'regular',
    label: 'Priority 2',
  },
];

export const ACCOUNTS_OPTIONS = [
  {
    id: 'in-itin',
    value: 'in-itin',
    label: 'In Itinerary',
  },
  {
    id: 'out-itin',
    value: 'out-itin',
    label: 'Out of Itinerary',
  },
];

export const BUCKETS_OPTIONS = [
  {
    id: 'Bucket 0',
    value: '0',
    label: 'Bucket 0',
  },
  {
    id: 'Bucket 1',
    value: '1',
    label: 'Bucket 1',
  },
  {
    id: 'Bucket 2',
    value: '2',
    label: 'Bucket 2',
  },
  {
    id: 'Bucket 3',
    value: '3',
    label: 'Bucket 3',
  },
  {
    id: 'Bucket 4',
    value: '4',
    label: 'Bucket 4',
  },
  {
    id: 'Bucket 5',
    value: '5',
    label: 'Bucket 5',
  },
  {
    id: 'Bucket >=6',
    value: '>=6',
    label: 'Bucket >=6',
  },
];

export const PAYMENT_NOTES_VALUES = {
  PROMISE_TO_PAY: 'Promise to Pay',
  PARTIAL_PAID: 'Partial Payment',
  FULLY_PAID: 'Full Payment',
  NOT_CONTACTABLE: 'Not Contactable',
  ALREADY_PAID: 'Already Made Payment',
  UNABLE_TO_PAY: 'Unable to Pay',
  FORECLOSURE: 'Foreclosure Payment',
  CUSTOMER_EXPIRY: 'Customer Expiry',
  REFERRAL_TO_RCU: 'Referral to RCU',
  REQUEST_FOR_OTS: 'Request for OTS',
  SETTLEMENT: 'Settlement',
  ONLINE_PAYMENT_REQUEST: 'Online Payment Request',
  WALLET_PAYMENT_REQUEST: 'Wallet Payment Request',
};

export const PAYMENT_NOTES_OPTIONS = [
  {
    id: 'full-payment',
    value: 'full-payment',
    label: 'Fully Paid',
  },
  {
    id: 'partial-payment',
    value: 'partial-payment',
    label: 'Partially Paid',
  },
  {
    id: 'already-paid',
    value: 'already-paid',
    label: 'Already Paid',
  },
  {
    id: 'not-contactable',
    value: 'not-contactable',
    label: 'Not Contactable',
  },
  {
    id: 'unable-to-pay',
    value: 'unable-to-pay',
    label: 'Unable To Pay',
  },
];

export const PAYMENT_COLLECTION_USER_ROLES = [
  {
    id: CASHIER_ROLE,
    value: CASHIER_ROLE,
    label: 'Cashier',
  },
  {
    id: COLLECTOR_ROLE,
    value: COLLECTOR_ROLE,
    label: Localization.collectionSpecialist,
  },
];

export const PAYMENT_CHANNELS_DROPDOWN = [
  {
    id: 'cash',
    value: 'cash',
    label: 'Cash',
  },
  {
    id: 'cheque',
    value: 'cheque',
    label: 'Cheque',
  },
  {
    id: 'dd',
    value: 'dd',
    label: 'DD',
  },
  {
    id: 'wallet',
    value: 'wallet',
    label: 'Wallet',
  },
  {
    id: 'qr-wallet',
    value: 'qr-wallet',
    label: 'QR Wallet',
  },
  {
    id: 'neft',
    value: 'neft',
    label: 'NEFT Payment',
  },
];

export const PAYMENT_NOTES_DROPDOWN = [
  {
    id: 'full-payment',
    value: 'full-payment',
    label: 'Fully Paid',
  },
  {
    id: 'partial-payment',
    value: 'partial-payment',
    label: 'Partially Paid',
  },
  {
    id: 'promise-to-pay',
    value: 'promise-to-pay',
    label: PROMISE_TO_PAY,
  },
  {
    id: 'not-paid',
    value: 'not-paid',
    label: 'Not Paid',
  },
  {
    id: 'already-made-payment',
    value: 'already-made-payment',
    label: 'Already Made Payment',
  },
  {
    id: 'unable-to-pay',
    value: 'unable-to-pay',
    label: 'Unable to Pay',
  },
  {
    id: 'not-contactable',
    value: 'not-contactable',
    label: 'Not Contactable',
  },
  {
    id: 'foreclosure-payment',
    value: 'foreclosure-payment',
    label: 'Foreclosed',
  },
  {
    id: 'referral-to-rcu',
    value: 'referral-to-rcu',
    label: 'Referral to RCU',
  },
  {
    id: 'customer-expiry',
    value: 'customer-expiry',
    label: 'Customer Expired',
  },
  {
    id: 'online-payment-request',
    value: 'online-payment-request',
    label: 'Online Payment Request',
  },
  {
    id: 'wallet-payment-request',
    value: 'wallet-payment-request',
    label: 'Wallet Payment Request',
  },
  {
    id: 'request-for-ots',
    value: 'request-for-ots',
    label: 'Request for OTS',
  },
  {
    id: 'settlement',
    value: 'settlement',
    label: 'Settlement',
  },
  {
    id: 'skipped-visit',
    value: 'skipped-visit',
    label: 'Skipped Visit',
  },
  {
    id: 'contactable',
    value: 'contactable',
    label: 'Contactable',
  },
];

export const CS_STATUS_FILTER = [
  {
    name: 'Active',
    key: 'active',
    level: 1,
  },
  {
    name: 'Inactive',
    key: 'inactive',
    level: 1,
  },
  {
    name: 'Suspended',
    key: 'suspended',
    level: 1,
  },
];

export const GEO_ACCURACY_DROPDOWN = [
  {
    id: 'high',
    value: 'high',
    label: 'High (< 2 km)',
  },
  {
    id: 'medium',
    value: 'medium',
    label: 'Medium (2-5 km)',
  },
  {
    id: 'low',
    value: 'low',
    label: 'Low (> 5 km)',
  },
];

export const BROADCAST_PLATFORM_OPTIONS = ['Web', 'Mobile', 'All'];

export const BROADCAST_PRIORITY_OPTIONS = ['Low', 'Medium', 'High'];

// export const BROADCAST_ROLES_OPTIONS = ['Manager', 'Cashier', 'Borrower', 'Dealer'];

export const BROADCAST_ROLES_OPTIONS = [
  {
    id: 'M',
    value: 'M',
    label: 'Manager',
  },
  {
    id: 'A',
    value: 'A',
    label: 'Cashier',
  },
  {
    id: 'B',
    value: 'B',
    label: 'Borrower',
  },
  {
    id: 'D',
    value: 'D',
    label: 'Dealer',
  },
];

export const handleUploadFile = async (data, id) => {
  const fileExt = data.name?.split('.') ? data.name?.split('.')[1] : 'xlsx';
  const { uploadUrl } = await requestUploadFile(id, fileExt, data);
  const uploadPayload = {
    url: uploadUrl,
    name: data.name,
    type: fileExt,
  };
  return uploadPayload;
};

export const validLinkURL = (str: string) => {
  const pattern = new RegExp(
    '^(https?://)?(([a-zd]([a-zd-]*[a-zd])*).)+[a-z]{2,}|((d{1,3}.){3}d{1,3})(:d+)?(/[-a-zd%_.~+]*)*',
  ); // fragment locator
  return pattern.test(str);
};
