import { ScrollData, StateTableYard, UserListData } from 'redux/yard/types';
import { isEmpty } from 'lodash';
import { StateAsset, StateAssetLog } from './AssetAPI/type';
import { StateBranchCode } from './BranchAPI/type';
import { StateBase } from './type';
import { TStateAgencyUser } from './AgencyUserAPI/type';

export const API = {
  ADMINISTRATION: {
    ACTIVITY: {
      LIST: '/activities-log',
    },
    ALLOCATION: {
      EXPORT_SUMMARY: '/allocations/export-summary',
      EXPORT_HISTORY: '/allocations/export-history',
      UPLOAD: '/bulk-upload/allocation',
      UPDATE_MAPPING: (id: number) => `/bulk-upload/${id}/update-mapping`,
    },
    AGENCY: {
      LIST: '/agency-v3',
      DETAIL: '/agency-v3',
      CREATE_AGENCY: '/agency-v3',
      PUT_AGENCY: '/agency-v3',
      UPLOAD: '/bulk-upload',
      UPDATE_MAPPING: (id: number) => `/bulk-upload/${id}/update-mapping`,
      GET_SEQ_NUMBER: 'agency-v3/agency-id/new',
      LIST_PORTFOLIO: '/agency-users/portfolio-categories',
    },
    USER: {
      CREATE_USER: '/users',
      LIST: '/users/list',
      ROLE: '/users/role',
      DOWNLOAD: '/users/download',
      EXPORT: '/users/export',
      UPDATE: (id: any) => `/users/${id}`,
      GET_DETAIL: (id: any) => `/users/${id}`,
      UPLOAD: '/bulk-upload/user',
      UPLOADCOH: '/bulk-upload/usercoh',
      UPDATE_MAPPING: (id: number) => `/bulk-upload/${id}/update-mapping`,
      UPDATE_COH_MAPPING: (id: number) => `/bulk-upload/${id}/update-coh-mapping`,
      UPDATE_INFO: '/users/updateinfo',
      LOCATIONS: '/users/location',
    },
    PAYMENT: {
      GET_COH_AND_PENDING_PAYMENT_CANCELLATION: (id: number) =>
        `/payments/get-coh-and-pending-payment-cancellation/${id}`,
    },
    ACCOUNT: {
      LIST: '/searchlist/get-accounts',
      UPLOAD: '/bulk-upload/account',
      UPLOAD_MONTHLY: '/bulk-upload/account-monthly',
      UPLOAD_REASSIGNMENT: '/bulk-upload/account-reassignment',
      CREATE_ACCOUNT: '/account',
      GET_USER_ACCOUNT: '/users/list',
      ERROR_DOWNLOAD: (id: any) => `/bulk-upload/${id}/error-file`,
      HISTORY_DETAIL: (id: any) => `/bulk-upload/${id}/accounts`,
      UPDATE_MAPPING: (id: number) => `/bulk-upload/${id}/update-mapping`,
      ACTIVE_ACCOUNT: '/bulk-upload/active-account',
      REUPLOAD: (id: number | string) => `/bulk-upload/${id}/reupload`,
    },
    TASK: {
      LIST: '/task',
      COMMENT: {
        POST: '/comment',
      },
      UPDATE_STATUS_TASK: '/task/:id',
      ADD_FILE: (id) => `/task/${id}/attachments`,
      LIST_USERS: '/task/users-to-add-collaborator',
      POST_USERS: '/task/:id/collaborators',
      UPDATE_COLLABORATORS: (id) => `/task/${id}/collaborators`,
      DELETE_COLLABORATORS: '/task/:id/collaborator/:user_id',
      DELETE_ATTACHMENTS: '/task/:id/attachments/:attachment_id',
      DOWNLOAD_ATTACHMENTS: '/task/:id/attachments/:attachment_id/download',
      EDIT: (id: number) => `/task/${id}`,
    },
    ROLE: {
      LIST: '/role',
      DETAIL_ROLE: (id: number) => `/role/${id}`,
      LIST_USER_ROLE: (page?: number, limit?: number, role?: number, keySearch?: string) =>
        `/role/users?page=${page}&limit=${limit}&role_id=${role}&search=${keySearch}`,
      SEND_REQUEST_EDIT_ROLE: (id: string) => `/role/${id}`,
    },
    ORGANISATIONS: {
      CREATE: '/data-group',
      GET: '/data-group',
      DELETE: (id: number) => `/data-group/${id}`,
      UPDATE: (id: number) => `/data-group/${id}`,
      GET_USERS: (id: number) => `/data-group/${id}/users`,
      ADD_USER: (id: number) => `/data-group/${id}/users`,
      REMOVE_USER: (id: number) => `/data-group/user-group/${id}`,
    },
    BOOK: {
      LIST: '/books',
      DETAIL: '/books/:id',
      ID_USER: '/books/users',
      PUT_BOOK: '/books/:id',
      CREATE_BOOK: '/books',
      UPLOAD: '/bulk-upload/book',
      UPDATE_MAPPING: (id: number) => `/bulk-upload/${id}/update-mapping`,
      HISTORY: '/bulk-upload',
    },
    BANK: {
      LIST_BANKS: (page: number, limit: number, keySearch?: string, active?: number, robotics_flag?: number) => {
        let urlBank: string = `/banks?limit=${limit}&page=${page}&offset=0&search=${keySearch}`;
        if (active?.toString()) {
          urlBank += `&active=${active}`;
        }
        if (robotics_flag?.toString()) {
          urlBank += `&robotics_flag=${robotics_flag}`;
        }
        return urlBank;
      },
      DETAIL_BANK: (id: number) => `/banks/${id}`,
      CREATE_BANK: '/banks',
      UPDATE_BANK: (id: number) => `/banks/${id}`,
      UPLOAD: '/bulk-upload/bank',
      UPDATE_MAPPING: (id: number) => `/bulk-upload/${id}/update-mapping`,
      DETAIL: '/banks/:bank_id',
      HISTORY: '/bulk-upload',
    },
    CLIENTS: {
      LIST: '/client',
      UPDATE_CLIENTS: (id: number) => `/client/${id}`,
    },
    COH_REASON: {
      GET: '/coh-reason',
      GET_DETAIL: (id: string) => `/coh-reason/${id}`,
      CREATE: '/coh-reason',
      UPDATE: (id: string) => `/coh-reason/${id}`,
    },
    BRANCH: {
      LIST: '/company-branch',
      UPLOAD: '/bulk-upload',
      UPDATE_MAPPING: (id: number) => `/bulk-upload/${id}/update-mapping`,
      LIST_CODE: (state?: StateBranchCode) => {
        let URLListCode = '/company-branch/branch-code';
        if (state) {
          URLListCode += `?page=${state.page}&limit=${state.limit}&search=${state.search}`;
        }

        return URLListCode;
      },
      DETAIL: (id: string) => `/company-branch/${id}`,
      UPDATE: (id: string) => `/company-branch/${id}`,
      CREATE: '/company-branch',
    },
    ASSETS: {
      GET: (state: StateAsset) => {
        let URLAssets = `/asset?page=${state.page}&limit=${state.limit}&search=${state.search}&os=${state.os}`;
        if (state?.active?.toString()) {
          URLAssets += `&active=${state.active}`;
        }
        return URLAssets;
      },
      GET_ALL: (state: StateAsset) => `/asset?search=${state.search}&os=${state.os}`,
      GET_DETAIL: (id: string) => `/asset/${id}`,
      CREATE: '/asset',
      UPDATE: (id: string) => `/asset/${id}`,
      UPLOAD: '/bulk-upload',
      GET_USER_ASSETS: (id: string) => `/asset/users/${id}`,
      UPDATE_MAPPING: (id: number) => `/bulk-upload/${id}/update-mapping`,
    },
    ASSETS_LOG: {
      GET: (state: StateAssetLog) =>
        `/asset/logs?page=${state.page}&limit=${state.limit}&search=${state.search}&status=${state.status}`,
      GET_ALL: (state: StateAssetLog) => `/asset/logs?search=${state.search}&status=${state.status}`,
    },
    PORTFOLIO: {
      LIST: '/portfolio',
      LOCATION: '/portfolio/location',
      CODE: '/portfolio/code',
    },
    USER_LIST: {
      USER_LIST: (state: UserListData) =>
        `/user-listing?page=${state.page}&limit=${state.limit}&search=${state.search}&roles=${state.roles}&status=${state.status}&suspended=${state.suspended}&paymentRecording=${state.paymentRecording}`,
    },
    SCROLL_LIST: {
      SCROLL_LIST: (state: ScrollData) =>
        `/scroll-listing?page=${state.page}&limit=${state.limit}&scrollStatus=${state.scrollStatus}&search=${state.search}`,
    },
    REPORT_LIST: {
      PAYMENT_CANCELLATION_REPORT_LIST: () => '/payment-cancellation-report',
      PAYMENT_RECEIPT_LIST: () => '/payment-receipt-report',
    },
    YARD: {
      GET_LIST: (state: StateTableYard) =>
        `/yards?page=${state.page}&limit=${state.limit}&search=${state.search}&status=${state.status}`,
      GET_ALL: (state: StateTableYard) => `/yards?search=${state.search}&status=${state.status}`,
      GET_LINK: (id: number) => `/yards/download-file/${id}`,
      GET_CODE: '/yards/generate-code',
      GET_PHONE_NUMBER: '/yards/list-phone-number',
      GET_DETAIL: (id: string) => `/yards/${id}`,
      CREATE: '/yards',
      UPDATE: (id: string) => `/yards/${id}`,
      DOWNLOAD_DOCUMENT: '/yards/download-file/:id',
    },
    BRANCH_CODE_MASTER: {
      GET_LIST: (state: StateBase) =>
        `/asset/branch-code?page=${state.page}&limit=${state.limit}&search=${state.search}`,
    },

    BIDDER: {
      GET_LIST: '/bidders',
      GET_DETAIL_BIDDER: (id: string) => `/bidders/${id}`,
      CREATE_BIDDER: '/bidders',
      UPDATE_BIDDER: (id: string) => `/bidders/${id}`,
      APPROVE_BIDDER: (id: string) => `/bidders/${id}/status`,
      GET_NUMBER_BIDDER: 'bidders/seq-number/new',
      GET_FILE: (id: string) => `/bidders/${id}/download`,
    },

    AGENCY_USER: {
      GET_LIST_AGENCY_USER: (state: TStateAgencyUser) => {
        let url = `/agency-users?suspended=${state.suspended}&search=${state.search}&sort=${state.sort}&status=${state.status}&role_code=${state.role_code}&agency_id=${state.agency_id}`;
        if (state.page && state.limit) {
          url += `&page=${state.page}&limit=${state.limit}`;
        }
        return url;
      },
      LIST_PORTFOLIO: '/agency-users/portfolio-categories',
      GET_FE_CODE: 'agency-users/agency-fe-code/new',
      SEND_SMS: '/agency-users/sms-agency',
      GET_DETAIL: '/agency-users',
      CREATE: '/agency-users',
      EDIT: (id: string) => `/agency-users/${id}`,
    },
    REPO_ACCOUNT: {
      DETAIL_SEIZURE: (id: string) => `/seizures/${id}`,
      DETAIL_REPOSSESSION: (id: string) => `/repossessions/${id}`,
      DETAIL_INWARD: (id: string) => `/repossessions/inward/${id}`,
      SUBMIT_CUSOTMER_RELEASE: (id: string) => `/repossessions/${id}/release`,
      APPROVE_REJECT_INWARD: (id: string) => `/repossessions/inward/${id}/approve_reject`,
    },
  },
};
